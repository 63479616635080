<template>
  <div class="card p-shadow-6">
    <h1>Acls - Categorias</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="listCategories"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="parentName"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem categorias. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('createAclsCategories')"
          >
            <Button icon="pi pi-plus" @click="newCategory()" />
            <Button
              v-if="can('seeAcls') || $store.state.auth.user.isEsa"
              label="Acls"
              class="p-ml-1"
              icon="pi pi-lock-open"
              @click="$router.push(`/acls`)"
            />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Categoria"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="parentName"
          header="Categoria Superior"
          filterField="parentName"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            <span class="p-column-title">Activa:</span>
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('updateAclsCategories')"
              icon="pi pi-pencil"
              v-tooltip.left="'Editar'"
              class="p-button-sm p-button-rounded p-button-success"
              @click="editCategory(slotProps.index, slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="categoryModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (categoryModel.id == undefined ? 'Criar' : 'Actualizar') +
        ' Categoria de ACL'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="aclCtegoryForm"
        class="p-col-12"
        @submit.prevent="saveCategory"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="categoryModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('categoryName') },
                  'form-control',
                ]"
                :name="'categoryName'"
              />
              <label for="categoryName">Nome da Categoria</label>
            </span>
            <small
              v-if="errors.has('categoryName')"
              class="p-error"
              role="alert"
            >
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <dropdown
                v-model="categoryModel.parentId"
                :options="listParentsCategories"
                optionLabel="name"
                optionValue="id"
                :filter="true"
                :showClear="true"
                :name="'parentCategory'"
                v-bind:class="['form-control']"
              />
              <label for="parentCategory">Categoria Superior</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2" v-if="categoryModel.id">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="admission">Ativa</label>
            </span>
            <InputSwitch
              v-model="categoryModel.active"
              name="admission"
              style="'top: 3px;"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveCategory"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeCategoryForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import aclsService from "../services/acls.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "AclsCategories",
  data() {
    return {
      loading: true,
      listCategories: [],
      listParentsCategories: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        parentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      categoryModel: { showForm: false },
    };
  },
  async created() {
    this.listCategories = await this.getCategoriesList();
    this.getParentCategories();
    this.loading = false;
  },
  methods: {
    getCategoriesList() {
      return aclsService.getCategories().then((response) => {
        response = response.map((v) => ({
          ...v,
          parentName: v.parent ? v.parent.name : "",
        }));
        return response;
      });
    },
    getParentCategories() {
      return (this.listParentsCategories = this.listCategories.filter(
        (category) => category.active == 1
      ));
    },
    closeCategoryForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.categoryModel = { showForm: false });
    },
    newCategory() {
      return (this.categoryModel.showForm = true);
    },
    editCategory(index, category) {
      return (this.categoryModel = {
        index: index,
        showForm: true,
        id: category.id,
        name: category.name,
        parentId: category.parent_id,
        active: category.active == 1 ? true : false,
        category: category,
      });
    },
    saveCategory() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.categoryModel.id === undefined) {
          return this.saveNewCategory();
        }

        return this.updateCategory();
      });
    },
    saveNewCategory() {
      let bodyParams = {
        name: this.categoryModel.name,
      };
      if (
        this.categoryModel.parentId != undefined &&
        this.categoryModel.parentId > 0
      ) {
        bodyParams.parent_id = this.categoryModel.parentId;
      }

      if (this.categoryModel.active !== undefined) {
        bodyParams.active = this.categoryModel.active;
      }

      return aclsService
        .addCategory(bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir a categoria",
              life: 3000,
            });
          }
          response.parentName = response.parent ? response.parent.name : "";
          this.listCategories.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Categoria inserida",
            detail: "A categoria foi inserida com sucesso",
            life: 3000,
          });
          return this.closeCategoryForm();
        })
        .then(() => this.getParentCategories());
    },
    updateCategory() {
      let bodyParams = {
        name: this.categoryModel.name,
      };
      if (
        this.categoryModel.parentId != undefined &&
        this.categoryModel.parentId > 0
      ) {
        bodyParams.parent_id = this.categoryModel.parentId;
      }

      if (this.categoryModel.active !== undefined) {
        bodyParams.active = this.categoryModel.active;
      }
      return aclsService
        .updateCategory(this.categoryModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a categoria",
              life: 3000,
            });
          }
          this.listCategories.splice(this.categoryModel.index, 1);
          this.listCategories[this.categoryModel.index] = response;
          this.listCategories[this.categoryModel.index].parentName =
            response.parent ? response.parent.name : "";
          this.$toast.add({
            severity: "success",
            summary: "Categoria atualizada",
            detail: "A Categoria foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeCategoryForm();
        })
        .then(() => this.getParentCategories());
    },
  },
};
</script>
